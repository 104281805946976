
import '../App.css';


import Web1 from '..//Assests/web1.jpg'
import Cowss  from '..//Assests/cowss.jpg'
import Pigeon from '..//Assests/Pigeon.jpg'
import Eagle from '..//Assests/Eagle.jpg'
import paypal from '..//Assests/paypal.png'
import Sheep from '..//Assests/Sheep.jpg'
import Sample from '../components/Sample';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  // API URL from .env or fallback to default production URL
  const apiUrl = process.env.REACT_APP_API_URL || 'https://touristattraction.shop';

  // Navigate to the FileUpload page
  const handleClick = () => {
    navigate('/FileUpload');
  };

  // Fetch posts from the server
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        console.log('Fetching from:', `${apiUrl}/server/posts`); // Adjusted endpoint for posts
        const response = await axios.get(`${apiUrl}/server/posts`);
        
        if (response.status !== 200) {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
 
        setPosts(response.data); // Ensure response.data is in the expected format
      } catch (error) {
        if (error.response?.status === 503) {
          console.error('Server is temporarily unavailable. Please try again later.');
        } else {
          console.error('Error fetching posts:', error.response ? error.response.data : error.message);
        }
      }
    };
  
    fetchPosts();
  }, [apiUrl]);
 
  return (
    <>
      <div>
        <center>
          <h1 style={{ color: '#003366', fontSize: '24px', fontWeight: 'bold', textAlign: 'center', lineHeight: '1.4', margin: '20px 0', padding: '20px', background: '#FFD700', borderRadius: '8px' }}>
            Post Your Ads All Product & Services On This Site For Just Rs,10/-
          </h1>
        </center>
        <center>
          <h1 style={{ color: '#003366', fontSize: '24px', fontWeight: 'bold', textAlign: 'center', lineHeight: '1.4', margin: '20px 0', padding: '20px', background: '#FFD700', borderRadius: '8px' }}>
            Foreign People Can Post Your Ads For Just $0.50/-
          </h1>
        </center>
        
        <center>
          <button
            onClick={handleClick}
            style={{
              backgroundColor: '#0056b3',
              color: '#fff',
              border: 'none',
              borderRadius: '8px',
              padding: '15px 30px',
              fontSize: '18px',
              cursor: 'pointer',
              transition: 'background-color 0.3s, transform 0.3s, box-shadow 0.3s',
              outline: 'none',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#004494';
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#0056b3';
              e.currentTarget.style.transform = 'scale(1.0)';
              e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
            }}
          >
            Users Post Ad Now
          </button>
        </center>

        <div
          style={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
            gap: '16px', 
            margin: '0 auto', 
            maxWidth: '1200px', 
            padding: '20px' 
          }}
        >
          {posts.length === 0 ? (
            <p>No posts available.</p>
          ) : (
            posts.map((post, index) => (
              <div
                key={post.id || index}
                style={{ 
                  background: '#f9f9f9', 
                  border: '1px solid #ddd', 
                  borderRadius: '8px', 
                  padding: '16px', 
                  textAlign: 'center', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  boxSizing: 'border-box',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  cursor: 'pointer'
                }}
                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
              >
                <p style={{ fontWeight: 'bold' }}>Title: {post.name}</p>
                <p>Number: {post.number}</p>
                <p>Location: {post.location}</p>
                <p>Price: Rs,{post.price}/-</p>
                <p>Description: {post.description}</p>
                <p>Transaction ID: {post.transactionId}</p>
                {post.photo && (
                  <img
                    src={`${apiUrl}/server/uploads/${post.photo}`}
                    alt={post.name}
                    style={{ 
                      maxWidth: '100%', 
                      height: 'auto', 
                      borderRadius: '4px', 
                      marginTop: '10px'
                    }}
                  />
                )}
              </div>
            ))
          )}
        </div>
      </div>
      
    

    
      <div className='Note'>
        <center>
          <h2>Welcome Amazon Affiliate Links Below Shop Now Please</h2>
        </center>
      </div>
      
      <center>
        <div className='ShopCall'>
          <Sample />
        </div>
      </center>
      
      <div className='headlines'>
        <center>
          <h2>Till Now $700 Received, And We Provided Food, Shelter For Animals And Birds</h2>
        </center>
      </div>
      
      <div className='text1'>
        <center>
          <h1>Donate Now For Wildlife</h1>
          <h1>Scan And Pay By Paypal</h1>
          <br />
          <img src={paypal} alt='Paypal QR Code' />
          <br />
        </center>
      </div>
      
      <div className='anchortag'>
        <center>
          <p>CheckOut Our Work On Youtube</p>
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtVE5AQl2wSGaNQhUn63qPdi'>1. Dogs Videos Click Me</a>
          </button>
          <br />
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtV_zlwR8IWqBknxXUrLIxAK'>2. Cow Videos Click Me</a>
          </button>
          <br />
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtX-EuiKLwOQcRceAr2Ue40y'>3. Pigeons Videos Click Me</a>
          </button>
          <br />
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtWL_CVwJTCp9FXmwE1WM8Yg'>4. Eagle Videos Click Me</a>
          </button>
          <br />
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtXDj_3tCH3IV5rSfmCHsb1Z'>5. Sheep Videos Click Me</a>
          </button>
          <br />
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtWn8UjdtyZcmbNKMMrRLVBt'>6. WildLife Videos Click Me</a>
          </button>
          <br />
        </center>
      </div>
      
      <div className='text'>
        <center>
          <h2>If You Want To Visit This Places Write To Us At donateforpoorhungry@gmail.com</h2>
          <h2>Donate For WildAnimals & Birds</h2>
        </center>
      </div>
      
      <div className='Row'>
        <div className='W'>
          <img src={Web1} alt='Web1' />
        </div>
        <div className='W'>
          <img src={Cowss} alt='Cowss' />
        </div>
        <div className='W'>
          <img src={Pigeon} alt='Pigeon' />
        </div>
      </div>
      
      <div className='Row'>
        <div className='W'>
          <img src={Eagle} alt='Eagle' />
        </div>
        <div className='W'>
          <img src={Sheep} alt='Sheep' />
        </div>
      </div>
      
      <div className='countries'>
        <center>
          <h2>Other Countries Amazon Shop Link Will Be Updated Daily So Visit This Website Please</h2>
        </center>
      </div>
    </>
  );
};

export default Home;

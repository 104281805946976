import {NavLink }from 'react-router-dom'
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className='Navbar'>
            
            <ul>
              
              <li className='a'>
                <NavLink to="/">Home</NavLink>

              </li>
              <li className='c'> 
                <NavLink to="/FileUpload">User Post</NavLink>
              </li>
              <li className='b'>
                <NavLink to="/Usa">USA</NavLink>
              </li>

               <li className='c'>
                <NavLink to="/UK"> UK</NavLink>
              </li>
              <li className='c'>
                <NavLink to="/Japan">Japan</NavLink>
              </li>
              <li className='c'>
                <NavLink to="/India">India</NavLink>
              </li>
              <li className='c'> 
                <NavLink to="/Payment">Wildlife Donation Request</NavLink>
              </li>
              <li className='c'> 
                <NavLink to="/About">About</NavLink>
              </li>
              <li className='c'> 
                <NavLink to="/Contact">ContactUs</NavLink>
              </li>
               
              
             
              
            </ul>
        </nav>
        
      

    )
}
export default Navbar
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // For navigation
import '../Pages/FileUpload.css'; // Import the CSS file
import PTM from '../Assests/PTM.jpg'; // Adjust the path as necessary
import PP from '../Assests/PP.png'; // Adjust the path as necessary


function FileUpload() {
  const [file, setFile] = useState(null);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      setErrorMessage('File size exceeds 5MB.');
      setFile(null);
    } else if (selectedFile && !['image/jpeg', 'image/png'].includes(selectedFile.type)) {
      setErrorMessage('Only JPEG and PNG files are allowed.');
      setFile(null);
    } else {
      setFile(selectedFile);
      setErrorMessage('');
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!name || !number || !location || !price || !description || !transactionId) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    try {
      setLoading(true);
      setErrorMessage('');
      setSuccessMessage('');

      const apiUrl = process.env.REACT_APP_API_URL;

      let uploadedFileName = '';
      if (file) {
        const formData = new FormData();
        formData.append('photo', file);

        const uploadResponse = await axios.post(`${apiUrl}/server/uploads`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (!uploadResponse.data.success) {
          setErrorMessage('File upload failed.');
          setLoading(false);
          return;
        }

        uploadedFileName = uploadResponse.data.file; 
      }

      const postResponse = await axios.post(`${apiUrl}/server/posts`, {
        name,
        number,
        location,
        price,
        description,
        transactionId,
        photo: uploadedFileName,
      });

      if (postResponse.data.success) {
        setSuccessMessage('Post and file uploaded successfully.');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        setErrorMessage('Failed to post the data.');
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="file-upload-container">
      <div className="form-and-images">
        <div className="form-container">
          <form onSubmit={onSubmit}>
            <h3>Post Your Ad</h3>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
            <input
              type="text"
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <input
              type="number"
              placeholder="Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <textarea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            <input
              type="text"
              placeholder="Transaction ID"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
            />
            <input
              type="file"
              onChange={onFileChange}
            />
            <button type="submit" disabled={loading}>
              {loading ? 'Uploading...' : 'Upload'}
            </button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
          </form>
        </div>
        <div className="images-container">
          <img src={PTM} alt="UPI QR Code" className="image" />
          <img src={PP} alt="PayPal QR Code" className="image" />
        </div>
      </div>
      <center>
        <h3>-- Post Your Ads With Above Form Details</h3>
        <h3>-- Pay Through UPI QR for Indians & For Foreigners Pay Through Paypal QR</h3>
        <h3>-- Without Payment Transaction ID Your Post Will Be Deleted</h3>
        <h3>-- Approved Ads Will Be Live For 30 Days On This Site</h3>
      </center>
      <br />
    </div>
  );
}

export default FileUpload;
